import pdf from "../assets/Catálogo 2024_1.pdf"
import "./Catalogue.css"

const Catalogue = () => {
    return (
        <section className="catalogue-container-container">
        <section className="catalogue-container">
            <article className="catalogue-btn-span">
        <span className="calatogue-span">Descarga nuestro catálogo digital e <span className="catalogue-2nd-span">inspirate con tus  espacios</span></span>
        <a className="catalogue-button" href={pdf} target="_blank" rel="noopener noreferrer">Descargar</a>
        </article>
        </section>
        </section>
    )

}
export default Catalogue;